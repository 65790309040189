// Custom variables (including functions and mix-ins)
// E.g., p { @import font-size($h1-font-size); }
@import "~themes/bootstrap-variables";

// Local class selectors go here. 
// Applied to only DOM elements directly defined in this component.

.component {
  text-align: center;
  color: $black-50;
  background-color: $bg;
  height: 100%;

  :global {
    // Global class selectors go here.
    // Applied to all DOM elements nested within this component.
    
  }
}

.gearsImg {
  opacity: 0.7;
}